.pReceipt h1, p {
    font-family: Lato;
}
.print-receipt table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.print-receipt td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.print-receipt tr:nth-child(even) {
    background-color: #dddddd;
}

@media print{
    .hide-on-print{
        display: none;
    }
}
